<template>  

 <nav id="navbar" class="bg-white z-50">
    <div class="flex flex-row container mx-auto py-4 justify-between">      
      <router-link to="/"><img src="@/assets/logo_nav.svg" class="w-16"></router-link> 
      <div class="flex flex-row space-x-8">
        <router-link to="/">Home</router-link> 
        <router-link to="/work">Work</router-link>        
        <router-link to="/about">Contact</router-link> 
        <!-- <a class="cursor-pointer" @click="scrollToBottom()">Contact</a>  -->
      </div>
    </div>
  </nav>
</template>

<script>
  
  export default{
    methods:{
      scrollToBottom(){
        window.scrollTo(0, document.body.scrollHeight);
      }
    },
    mounted(){
      window.onscroll = function() {scrollFunction()};

      function scrollFunction() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          document.getElementById("navbar").style.top = "0";
        } else {
          document.getElementById("navbar").style.top = "-60px";
        }
      }
    }
  }
</script>

<style scoped>
  nav {
    position: fixed;
    top: -60px;
    width: 100%;
    display: block;
    transition: top 0.3s;

  }
  nav a {
    font-weight: bold;
    color: #19362b;
  }

  nav a.router-link-exact-active {
    color: #169a72;
  }
  
</style>