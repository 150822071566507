<template>
  <!-- banner -->
  <section>  
    <!-- <BannerTwo></BannerTwo> -->
    <BannerOne></BannerOne>
  </section>

  <!-- about -->
  <section>
    <div class="container mx-auto mt-24">    
      <h1 class="heading">Who we are</h1>
      <p class="mb-8">
        JOAG "যোগ" in the Bengali language means to add and inherently connect. JOAG is built on this cohesive nature through a group of skilled individuals and network who are determined and dedicated to assist diverse organisations and individuals to successfully emerge in their respective markets. We work through cooperation and collaboration to ensure results that not only benefit the current process but become a sustainable and encouraging set
      </p>
    </div>
      
    <span class="banner bg-banner">Vision</span>
    <div class="container mx-auto flex flex-row mb-8">    
      <div class="flex-initial flex flex-col">      
        <img src="@/assets/quote.svg" class="w-20 self-end">        
        <p class="font-black text-xl italic">Connecting diverse industries to collaborate, </p>
        <p class="font-black text-xl italic">co-emerge  and co-operate in their dedicated markets.</p>
      </div>
    </div>

    <span class="banner bg-banner">Mission</span>
    <div class="container mx-auto mb-24">    
      <ul class="list-disc mt-8">      
        <li>We encourage our partners and clients to become conscious communities.</li>
        <li>We promote collaboration over competition.</li>
        <li>We assist brands and individuals to evolve and establish themselves in their dedicated market. We promote sustainability, ethical trade and equal rights for all.</li>
        <li>We align our entire ecosystem with the Sustainable Development Goals.</li>
        <li>We connect through technology and empathy.</li>
      </ul>
    </div>
  </section>

  <!-- services -->
  <section>    
    <div class="relative">      
      <img src="@/assets/sailor2.jpg" class="w-full object-cover h-[700px] object-center para-image">
      <div class="absolute inset-0 bg-jgreen-300/50 ">
        <div class="container mx-auto">          
          <!-- <h1 class="heading text-white mt-24 pb-8">How We Connect</h1>     -->
          <h1 class="heading text-white mt-24 pb-8">Services</h1>    
          <div class="grid grid-cols-3 gap-24">
            <Service v-for="service in services" :msg="service.message" :source="service.source"></Service>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- clients -->
  <section> 
    <div class="container mx-auto my-24">
      <h1 class="heading">Our Clients</h1>
      <p class="font-medium">    
        We have been extremely proud of our portfolio. Our journey has been connected with diverse organisations and individuals who are equally and encouragingly aligned with our mission and vision. Therefore our professional path not only feels cohesive but it’s becoming a big small conscious community.
      </p>
      <div class="splide" aria-label="Splide Basic HTML Example">
        <div class="splide__track">
          <ul class="splide__list">
            <li class="splide__slide bg-jgreen-200 py-12 mx-2" v-for="i in clients">
              <img :src="require(`@/assets/${i}`)" class="m-auto">           
            </li>            
          </ul>
        </div>
      </div>
    <!--   
      <div class="grid grid-cols-4 gap-4">
        <div class="bg-jgreen-100 py-12" v-for="i in 15">
          <img src="@/assets/banner_bg.svg" class="mx-auto">
        </div>
      </div>
     -->
  
    </div>
  </section>

  <!-- footer -->
  <section>      
   <Footer></Footer>
  </section>

</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import BannerOne from '@/components/BannerOne.vue'
import BannerTwo from '@/components/BannerTwo.vue'
import Service from '@/components/Service.vue'
import Footer from '@/components/Footer.vue'
import simpleParallax from 'simple-parallax-js'
import '@splidejs/splide/css/sea-green'
import Splide from '@splidejs/splide';

export default {
  name: 'HomeView',
  components: {
    HelloWorld, Service, BannerOne, BannerTwo, Footer
  },
  data(){
    return{
      services:[
        {message:"Corporate Communication", source:"service_exclamation.png"},
        {message:"Business Development", source:"service_hand.png"},
        {message:"Partnership & Collaboration", source:"service_network.png"},
        {message:"Public Relations & Representation", source:"service_people.png"},
        {message:"Crisis Management", source:"service_stats.png"},
        {message:"Green & Sustainability Advisory", source:"service_world.png"}
      ],
      clients:[
        "client1.png",
        "client2.png",
        "client3.png",
        "client4.png",
        "client5.png",
        "client6.png",
        "client7.png"
      ]
    }
  },
  mounted(){
    var image = document.getElementsByClassName('para-image');
    new simpleParallax(image,{
      scale:1.5
    });

    new Splide( '.splide',{
      type   : 'loop',
      perPage: 3,
      perMove: 1,
    }).mount();
  }
}
</script>

<style scoped>
.bg-banner{
  background-image: url(../assets/diagonal_shape.svg);
}
.bg-cng{
  background-image: url(../assets/cng.jpg);
}

</style>
