<template>  
  <div class="container mx-auto"> 
	  <div class="flex flex-row pt-24 pb-12">
	  	<button v-for="(client,index) in clients" @click="change(index)" class="border-jgreen-400 pb-2 px-8 box-content" :class="{'border-b-2' : active==index}">
	  		{{client.name}}
	  	</button>
	  </div>
	  <div class="grid grid-cols-3 gap-8 pb-24">
	  	<!-- <img  v-for="i in clients[active].images" :src="'@/assets/'+i"> -->
	  	<img  v-for="i in clients[active].images" :src="require(`@/assets/${i}`)">
	  </div>
  </div>
</template>

<script>
export default{
	data(){
		return{
			active:0,
			clients:[
				{
					name: "Marsha Health Care", 
					images:[
						"logo.svg",
						"logo.svg",
						"logo.svg",
						"logo.svg",
						"logo.svg",
						"logo.svg",
						"logo.svg",
						"logo.svg",
						"logo.svg",
						"logo.svg",
					] 
				},
				{
					name: "ZE Project", 
					images:[
						"quote.svg",
						"quote.svg",
						"quote.svg",
						"quote.svg",
						"quote.svg",
					] 
				},
				{
					name: "Edutech", 
					images:[
						"banner_bg.svg",
						"banner_bg.svg",
						"banner_bg.svg",
						"banner_bg.svg",
						"banner_bg.svg",
						"banner_bg.svg",
						"banner_bg.svg",
					] 
				},
				{
					name: "Beyond the Binary", 
					images:[
						"logo.svg",
						"logo.svg",
						"logo.svg",
						"logo.svg",
						"logo.svg",
					] 
				},
				{
					name: "IHW", 
					images:[
						"quote.svg",
						"quote.svg",
						"quote.svg",
						"quote.svg",
					] 
				},
				{
					name: "UPCYBOM", 
					images:[
						"quote.svg",
						"quote.svg",						
						"quote.svg",
					] 
				},
				{
					name: "KHELO DHAKA", 
					images:[
						"logo.svg",
						"logo.svg",
						"logo.svg",
						"logo.svg",
						"logo.svg",
						"logo.svg",
						"logo.svg",
						"logo.svg",
						"logo.svg",
					]
				}
			]
		}
	},
	methods:{
		change(arg){
			this.active=arg			
		}
	}

}
</script>

