<template>
  <section>
    <div class="bg-boats bg-cover bg-repeat-no bg-center bg-fixed">
      <div class="bg-jgreen-500/75">        
        <div class="container mx-auto">      
          <h1 class="text-white text-6xl font-black py-28 text-center">Our Work</h1>
        </div>
      </div> 
    </div>
  </section>

  <section>    
    <Gallery></Gallery>
  </section>
  
  <section>
    <Footer></Footer>
  </section>
</template>

<script>
  import Gallery from '@/components/Gallery.vue'
  import Footer from '@/components/Footer.vue'

  export default{
    components:{Gallery, Footer}
  }
</script>

<style scoped>
  .bg-boats{
    background-image: url(../assets/two_boats.jpg);
  }
</style>