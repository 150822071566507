<template>
	<div class="bg-jgreen-50">
	  <div class="container mx-auto py-28">	  	

	      <div class="flex flex-row">	      	
			  <div class="flex flex-col">
			  	<p class="text-2xl">Connecting diverse industries to <span class="font-black">collaborate</span>, </p>
		        <p class="text-2xl"><span class="font-black">Co-emerge</span>  and <span class="font-black">co-operate</span> in their dedicated markets.</p>
			  </div>

			  <div></div>
	      </div>		
	  </div>
	</div>

</template>