<template>
  <MenuOne></MenuOne>
  <!-- <MenuTwo></MenuTwo> -->
  <router-view/>

</template>

<script>
  import MenuOne from '@/components/MenuOne.vue'
  import MenuTwo from '@/components/MenuTwo.vue'
  export default{

    components:{
      MenuOne,MenuTwo
    },
    methods:{
    },
    mounted(){
    }
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;    
}

</style>
