<template>  
  <div class="flex flex-col space-y-4">    
    <img :src="require(`@/assets/${source}`)" class="w-24 mx-auto">    
    <p class="font-black text-white text-xl text-center">{{msg}}</p>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
    source: String
  }
}
</script>

