<template>  

 <nav id="navbar" class="bg-jgreen-50 z-50">
    <div class="flex flex-row container mx-auto py-4 justify-between">      
      <img src="@/assets/logo.svg" class="w-20">
      <div class="flex flex-row space-x-8 items-center">
        <router-link to="/">Home</router-link> 
        <router-link to="/work">Work</router-link>        
        <router-link to="/about">Contact</router-link> 
        <!-- <a class="cursor-pointer" @click="scrollToBottom()">Contact</a>  -->
      </div>
    </div>
  </nav>
</template>

<script>
  
  export default{
    methods:{
      scrollToBottom(){
        window.scrollTo(0, document.body.scrollHeight);
      }
    },
    mounted(){
    }
  }
</script>

<style scoped>
  nav {
    position: fixed;    
    width: 100%;
    display: block;
    transition: top 0.3s;

  }
  nav a {
    font-weight: bold;
    color: #19362b;
  }

  nav a.router-link-exact-active {
    color: #169a72;
  }
  
</style>