<template>
  <div class="hello">
    <h1 class="bg-gray-400">{{ msg }}</h1>
    <div v-for="i in 10">
      <p class="font-bold ">hey {{i}}</p>
    </div>    
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

