<template>
  <div class="bg-ferris bg-cover bg-repeat-no bg-center bg-fixed">
    <div class="bg-jgreen-500/75 py-12">      
      <img src="@/assets/logo_white.svg" class="mx-auto py-8 w-40">
      <div class="flex flex-row text-white justify-center space-x-12 pb-8">
        <a href="https://www.linkedin.com/company/joagconsultants/"><img src="@/assets/li.svg" class="w-6"></a>
        <a href="https://www.facebook.com/joagconsultants.fb/"><img src="@/assets/fb.svg" class="w-6"></a>
        <a href="https://instagram.com/joagconsultants?igshid=YmMyMTA2M2Y="><img src="@/assets/ig.svg" class="w-6"></a>
      </div>
    </div>
    <div class="container mx-auto h-full flex flex-row justify-end items-end py-8 hidden">      
      <div class="text-white font-bold bg-jgreen-100/75 p-12">
        <p class="text-xl ">Contact Us</p>
        <p class="">Phone: +8801312881872</p>
        <p class="">Email: trb@joagconsultants.com</p>
        <p class="">Address: House 09, Floor 2, Road 16, Gulshan-1</p>
      </div>
    </div>
  </div>

  <div class="bg-jgreen-300 text-sm text-white py-4">
    <div class="container mx-auto flex flex-row justify-between">      
      <div>© Joag Consultants. Developed by Bahr</div>
      <div class="flex flex-row space-x-8 font-bold">
      	<router-link to="/">Home</router-link> 
      	<router-link to="/work">Work</router-link>        
      	<router-link to="/about">Contact</router-link> 
      </div>
      
    </div>
  </div>	
  
</template>

<style scoped>
	.bg-ferris{
  background-image: url(../assets/ferris_wheel.jpg);
}
</style>